const theme = {
  grid: {
    siteWidth: 1170,
    trackWidth: 378,
    columns: 12,
    small: 769,
    medium: 900,
    large: 1170,
    gutter: '12px',
  },
  font: {
    headStack: 'Roboto,Arial,Helvetica Neue,Helvetica,sans-serif',
    bodyStack: '"Roboto Condensed",Arial,Helvetica Neue,Helvetica,sans-serif',
    primaryColor: '#fff',
    baseSize: '16px',
    p: {
      size: '18px',
      weight: '400',
      height: '',
    },
    heading: {
      size: '16px',
      weight: '400',
      height: '',
      h1: { size: '48.83px' },
      h2: { size: '39.06px' },
      h3: { size: '31.25px' },
      h4: { size: '25px' },
      h5: { size: '20px' },
    },
  },
  color: {
    background: '#474959',
    highlight: '#73D6CF',
    white: '#fff',
    dark: '#474959',
  },
  menuText: '#000',
  bannerRibbon: {
    Start: '#444',
    End: '#333',
  },
  bannerCard: '#999',
}

export default theme
