import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Button = ({
  link,
  text,
  children,
  style,
  buttonType,
  className,
  onClick,
}) => {
  return link ? (
    <Link to={link} onClick={onClick} style={style} className={className}>
      {text || children}
    </Link>
  ) : (
    <button
      onClick={onClick}
      style={style}
      className={className}
      type={buttonType || 'button'}
    >
      {text || children}
    </button>
  )
}

Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
  buttonType: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

Button.defaultProps = {}

export default Button
