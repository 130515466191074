import styled, { css } from 'styled-components'

import Button from './Button'

const ButtonStyled = styled(Button)`
  display: inline-block;
  appearance: none;
  background: transparent;
  ${p =>
    p.buttonStyle === 'dark'
      ? css`
          border: 2px solid ${p => p.theme.color.dark};
          color: ${p => p.theme.color.dark};
        `
      : css`
          border: 2px solid ${p => p.theme.color.white};
          color: ${p => p.theme.color.white};
        `}
  padding: 5px 25px;
  text-align: center;
  border-radius: 16px;
  width: ${p => (p.wide ? '100%' : 'auto')};
  cursor: pointer;
  transition: background 200ms;
  &:hover {
    background-color: rgba(175, 175, 175, 0.3);
  }
`

export default ButtonStyled
