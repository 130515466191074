import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const FooterNav = ({ links, className, style }) => (
  <nav className={className} style={style}>
    {links && (
      <ul>
        {links.map(l => (
          <li>
            <Link to={l.url}>{l.title}</Link>
          </li>
        ))}
      </ul>
    )}
  </nav>
)

const FooterNavStyled = styled(FooterNav)`
margin-top: 30vh;
padding: 20px 0;
ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
li a {
  color: ${p => p.theme.color.white};
  font-size: ${p => p.theme.font.p.size};
  font-family: ${p => p.theme.font.headStack};
  text-decoration: none;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
`

FooterNav.propTypes = {
  siteTitle: PropTypes.string,
}

FooterNav.defaultProps = {
  siteTitle: ``,
}

export default FooterNavStyled
