import styled from 'styled-components'

const Column = styled.div`
  box-sizing: border-box;
  padding: 0 ${p => p.theme.grid.gutter};
  width: 100%;
  @media screen and (min-width: ${p => p.theme.grid.small}px) {
    ${p =>
      p.small ? `width:${(100 / p.theme.grid.columns) * p.small}%` : null};
  }
  @media screen and (min-width: ${p => p.theme.grid.medium}px) {
    ${p =>
      p.medium ? `width:${(100 / p.theme.grid.columns) * p.medium}%` : null};
  }
  @media screen and (min-width: ${p => p.theme.grid.large}px) {
    ${p =>
      p.large ? `width:${(100 / p.theme.grid.columns) * p.large}%` : null};
  }
`

export default Column
