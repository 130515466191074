import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyles } from '../styles/styling'

import Header from 'components/header'
import Footer from './footer'

const links = [
  {
    url: '/',
    title: 'Home',
  },
  {
    url: '/about',
    title: 'About',
  },
  {
    url: '/work',
    title: 'Work',
  },
]

const Layout = ({ children, homepage }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Header
            {...(homepage ? { homepage } : {})}
            siteTitle={data.site.siteMetadata.title}
            links={links}
          />
          <main>{children}</main>
          {/* <Footer links={links} siteTitle={data.site.siteMetadata.title} /> */}
        </>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
