import React from 'react'
import PropTypes from 'prop-types'

const Para = ({ children, className, style }) => (
  <p className={className} style={style}>
    {children}
  </p>
)

Para.propTypes = {
  children: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}

Para.defaultProps = {
  siteTitle: ``,
}

export default Para
