import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Row, Container, Column } from 'components/Layouts'

const Sticky = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  opacity: 0.2;
`

const Structure = ({ left, centre, right, sticky, children }) => (
  <Row>
    <Container>
      <Column small={0} medium={2}>
        {left || children}
      </Column>
      <Column small={5} medium={4}>
        {centre}
      </Column>
      <Column small={7} medium={6}>
        {sticky ? <Sticky>{right}</Sticky> : right}
      </Column>
    </Container>
  </Row>
)

Structure.propTypes = {
  children: PropTypes.object,
}

Structure.defaultProps = {
  siteTitle: ``,
}

export default Structure
