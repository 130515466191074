import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Tooltip = ({ children, content, className }) => {
  const [openTooltip, toggleTooltip] = useState(false)

  return (
    <div className={className}>
      <div className="wrapper" onClick={() => toggleTooltip(!openTooltip)}>
        {children}
      </div>
      {openTooltip && <div className="content">{content}</div>}
    </div>
  )
}

Tooltip.propTypes = {
  source: PropTypes.string,
}

Tooltip.defaultProps = {
  siteTitle: ``,
}

export default Tooltip
