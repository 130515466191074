import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Para from './Para'

const ParaStyled = styled(Para)`
  font-family: ${props => props.theme.font.bodyStack};
  font-size: ${p => p.theme.font.p.size};
  color: ${p => p.theme.color.white};
  &:first-child {
    margin-top: 0;
  }
  a {
    color: ${p => p.theme.color.highlight};
    text-decoration: none;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`

ParaStyled.propTypes = {
  siteTitle: PropTypes.string,
}

ParaStyled.defaultProps = {
  siteTitle: ``,
}

export default ParaStyled
