import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { Heading, Para, Button, Structure, FooterNav } from 'components'

const Center = styled.div`
  margin: 30vh 0;
  text-align: center;
`
const FooterWrap = styled.footer`
  margin: 30vh 0;
  text-align: center;
`

const FooterMain = ({ siteTitle }) => (
  <Center>
    <Para>Some more site info</Para>
    <Para>
      &copy;{siteTitle} {new Date().getFullYear()}
    </Para>
    <Button text="Contact" />
  </Center>
)

const Footer = ({ siteTitle, links }) => (
  <FooterWrap>
    <Structure
      centre={<FooterMain siteTitle={siteTitle} />}
      right={<FooterNav links={links} />}
    />
  </FooterWrap>
)

export default Footer
