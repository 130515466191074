import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Image from './Image'

const ImageStyled = styled(Image)`
  width:100%;
`

ImageStyled.propTypes = {
  siteTitle: PropTypes.string,
}

ImageStyled.defaultProps = {
  siteTitle: ``,
}

export default ImageStyled
