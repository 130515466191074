import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Heading from './Heading'

const HeadingStyled = styled(Heading)`
  font-family: ${props => props.theme.font.headStack};
  font-size: ${p =>
    p.level
      ? p.theme.font.heading[`h${p.level}`].size
      : p.theme.font.size.heading.h5.size};
  color: ${p => p.theme.font.primaryColor};
  &:first-child {
    margin-top: 0;
  }
`

HeadingStyled.propTypes = {
  siteTitle: PropTypes.string,
}

HeadingStyled.defaultProps = {
  siteTitle: ``,
}

export default HeadingStyled
