import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import ContactForm from './ContactForm'

const ContactFormStyled = styled(ContactForm)`
  div {
    position: relative;
    margin-top: 5px;
  }
  legend {
    position: absolute;
    top: -12px;
    left: 10px;
    background: #fff;
    font-size: ${p => p.theme.font.p.size};
    font-family: ${p => p.theme.font.headStack};
  }
  input,
  textarea {
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    font-size: ${p => p.theme.font.heading.h5.size};
    margin-bottom: 15px;
    padding: 6px;
    font-family: ${p => p.theme.font.bodyStack};
    resize: none;
  }
`

ContactFormStyled.propTypes = {
  siteTitle: PropTypes.string,
}

ContactFormStyled.defaultProps = {
  siteTitle: ``,
}

export default ContactFormStyled
