import React from 'react'
import styled, { css } from 'styled-components'

import {
  Heading,
  Para,
  Button,
  Structure,
  // MainNav,
  Tooltip,
  ContactForm,
} from 'components'

const Center = styled.div`
  ${p =>
    !p.header &&
    css`
      margin: 30vh 0;
    `};
  text-align: center;
`

const MainContent = ({ siteTitle }) => (
  <Center>
    <Heading level={1}>{siteTitle}</Heading>
    <Para>Web Development</Para>
    <Tooltip content={<ContactForm />}>
      <Button text="Contact" />
    </Tooltip>
  </Center>
)

const Header = ({ siteTitle, links }) => (
  <Structure
    centre={<MainContent siteTitle={siteTitle} />}
  // right={<MainNav links={links} />}
  />
)

export default Header
