import styled from 'styled-components'

const Container = styled.div`
  max-width: ${props =>
    props.theme && props.theme.siteWidth ? props.theme.siteWidth : 1440}px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`

export default Container
