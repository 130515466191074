import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'components'

const MainNav = ({ links, className, style }) => {
  const [open, toggleOpen] = useState(false)
  return (
    <>
      <nav className={className} style={style}>
        <Button className="mobile-nav" onClick={() => toggleOpen(!open)}>
          ...
        </Button>
        {links && (
          <ul className={open && 'mobile-open'}>
            {links.map(l => (
              <li>
                <Link to={l.url}>{l.title}</Link>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </>
  )
}

const MainNavStyled = styled(MainNav)`
  position: fixed;
  right: 12px;
  bottom: 12px;
  ul {
    display: none;
    position: absolute;
    bottom: 50px;
    right: 10px;
    text-align: right;
    list-style: none;
    &.mobile-open {
      display: block;
    }
  }
  li a {
    color: ${p => p.theme.color.white};
    font-size: ${p => p.theme.font.heading.h5.size};
    font-family: ${p => p.theme.font.headStack};
    text-decoration: none;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .mobile-nav {
    position: fixed;
    right: 12px;
    bottom: 12px;
    font-size: 50px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    /* background: url(); */
    width: 60px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    background-color: orange;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.3);
  }
  @media screen and (min-width: ${p => p.theme.grid.small}px) {
    position: static;
    margin-top: 30vh;
    padding: 20px 0;
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;
      position: static;
    }
    li a {
      color: ${p => p.theme.color.white};
      font-size: ${p => p.theme.font.p.size};
      font-family: ${p => p.theme.font.headStack};
      text-decoration: none;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
    .mobile-nav {
      display: none;
    }
    &:before {
      content: none;
    }
  }
`

MainNav.propTypes = {
  siteTitle: PropTypes.string,
}

MainNav.defaultProps = {
  siteTitle: ``,
}

export default MainNavStyled
