import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import Tooltip from './Tooltip'

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`

const TooltipStyled = styled(Tooltip)`
  position: relative;
  display: inline-block;
  .wrapper {
    cursor: pointer;
  }
  .content {
    background-color: ${p => p.theme.color.white};
    border-radius: 5px;
    padding: ${p => p.theme.grid.gutter};
    position: absolute;
    width: 378px;
    margin-top: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.5);
    animation: ${slideUp} 200ms;
  }
`

TooltipStyled.propTypes = {
  siteTitle: PropTypes.string,
}

TooltipStyled.defaultProps = {
  siteTitle: ``,
}

export default TooltipStyled
