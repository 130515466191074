import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed');
  html{
    font-size: ${props => props.theme.baseFontSize}px;
  }
  body {
    font-size: 1rem;
    margin: 0;
    font-family: ${props => props.theme.fontStack};
    background-color: ${p => p.theme.color.background};
  }
  *{
    box-sizing:border-box;
  }
  /* h1{
    font-size:2.441rem;
  }
  h2{
    font-size:1.953rem;
  }
  h3{
    font-size:1.563rem;
  }
  h4{
    font-size:1.25rem;
  } */
`

export default GlobalStyle
