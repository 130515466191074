import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ source, className, style, width, height }) => <img src={source} className={className} style={style} width={width} height={height} />

Image.propTypes = {
  source: PropTypes.string,
}

Image.defaultProps = {
  siteTitle: ``,
}

export default Image
