import React from 'react'
import PropTypes from 'prop-types'

const Heading = props =>
  React.createElement(
    `h${props.level ? props.level : 5}`,
    { className: props.className },
    [...props.children]
  )

Heading.propTypes = {
  siteTitle: PropTypes.string,
}

Heading.defaultProps = {
  siteTitle: ``,
}

export default Heading
