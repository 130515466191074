import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components'

const ContactForm = ({ className }) => {
  const [message, setMessage] = useState({ email: '', body: '' })
  const [submitted, submit] = useState(false)
  return submitted ? (
    <p className={className}>Thank you for getting in touch</p>
  ) : (
    <form
      className={className}
      onSubmit={() => submit(true)}
      action="https://docs.google.com/forms/d/e/1FAIpQLSezXc16GcqPHpySvG3BnrhslDf6CYtU-HHk9ZEWmCivtKhOrQ/formResponse"
    >
      <div>
        <legend htmlFor="emailAddress">Email</legend>
        <input
          type="email"
          name="emailAddress"
          value={message.email}
          onChange={e => setMessage({ ...message, email: e.target.value })}
        />
      </div>
      <div>
        <legend htmlFor="entry.732144250">Message</legend>
        <textarea
          rows="5"
          name="entry.732144250"
          value={message.body}
          onChange={e => setMessage({ ...message, body: e.target.value })}
        />
      </div>
      <Button buttonStyle="dark" buttonType="submit">
        Submit
      </Button>
    </form>
  )
}

ContactForm.propTypes = {
  source: PropTypes.string,
}

ContactForm.defaultProps = {
  siteTitle: ``,
}

export default ContactForm
